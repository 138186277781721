<template>
  <div>
    <CTabs 
      variant="tabs" 
      :active-tab="0"
      :fill="true"
      @update:activeTab	="hideTableOnImport"
      class="mb-4"
    >
      <CTab title="Exportar">
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          class="flex flex--space-between text-left shadow-none card-header w-100 bt-0 b-radius-0 b-grey"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CForm @submit="submitFilterValues">
            <CCard class="tab-card">
              <CCardBody>
              <CRow>
                <CCol sm="12">
                  <label class="filter-label">{{ messages.filters.area }}</label>
                  <FiltersAreaCombo :activeElements="activeItems" :allCountries="true" />
                  <span
                    v-if="areaError"
                    class="error-message error-message--bottom"
                    >{{ messages.validation.filterAreaCombo }}</span
                  >
                </CCol>
              </CRow>
              <CRow>
                <CCol xl="3">
                  <label class="filter-label">FY</label>
                  <FilterFY />
                </CCol>
                <CCol xl="6">
                  <label class="filter-label">{{
                    messages.filters.period
                  }}</label>
                  <FilterMonthsRange />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CRow alignHorizontal="between">
                <CCol>
                  <CButton type="submit" color="primary">Mostrar</CButton>
                </CCol>
                <CCol class="text-right">
                  <CButton
                    color="success"
                    style="white-space: nowrap"
                    @click="requestDownload($event)"
                    >Descargar
                    <LogoExcel />
                  </CButton>
                </CCol>
              </CRow>
            </CCardFooter>
            </CCard>
          </CForm>
        </CCollapse>
      </CTab>
      <CTab title="Importar">
        <CForm @submit="importData">
          <CCard class="tab-card">
            <CCardBody>
              <CRow>
                <CCol>
                  <div class="filter-label d-flex flex--space-between flex--align-items-end mb-2">
                    <label class="m-0">Seleccionar archivo</label>
                    <CButton 
                      color="light" 
                      class="mb-2"
                      @click="downloadTemplate('Plantilla-import-compras-Adhoc.xlsx')"
                    >
                      {{messages.purchasesAdhoc.donwloadImportTemplate}}
                    </CButton>
                  </div>
                  <div class="file-box w-100 bg-gray-100 border" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input 
                      type="file" 
                      id="assetsFieldHandle" 
                      class="w-0 h-0 d-block opacity-0 overflow-hidden absolute" 
                      @change="updateSelectedFile" 
                      ref="file" 
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                    <label 
                      v-show="!selectedFile.length && !showBools.okMessage && !showBools.koMessage" 
                      for="assetsFieldHandle" 
                      class="block cursor-pointer"
                    >
                      <div>
                        Arrastrar o <u>seleccionar</u> el archivo<br>
                        <CIcon size="xl" class="mt-4" name="cil-arrow-thick-from-bottom" />
                      </div>
                    </label>
                    <div v-show="selectedFile.length" v-cloak>
                      <span class="text-sm p-1" v-for="file in selectedFile">
                        {{ file.name }}
                        <CButton 
                          class="ml-2" 
                          type="button" 
                          @click="removeSelectedFile(selectedFile.indexOf(file))" 
                          title="Eliminar Archivo"
                          color="secondary"
                        >
                          Eliminar
                        </CButton>
                      </span>
                    </div>
                    <div v-show="showBools.okMessage" class="ok-message">
                      <CIcon size="xl" name="cil-check-circle" /> Datos cargados<br>
                      <CButton class="mt-3" color="success" @click="updateImportFields">Aceptar</CButton>
                    </div>
                    <div v-show="showBools.koMessage" class="ok-message">
                      <CIcon size="xl" name="cil-x-circle" /> 
                      ERROR {{ errorCode }}<br> 
                      {{ errorMessage }}
                      <br>
                      <CButton class="mt-3" color="danger" @click="updateImportFields">Aceptar</CButton>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter v-show="selectedFile.length">
              <CButton type="submit" color="primary">Cargar datos</CButton>
            </CCardFooter>
          </CCard>
        </CForm>
      </CTab>
    </CTabs>
    <CRow v-if="showBools.loader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showBools.exportKoMessage" class="fadeIn anim-speed--2s">
      <CCol sm="12" md="6" class="mx-auto mt-3 mb-5">
        <div class="ok-message">
          <CIcon size="xl" name="cil-x-circle" /> 
          ERROR {{ exportErrorCode }}<br> 
          {{ messages.errors[400] }}
          <br>
          <CButton class="mt-3" color="danger" @click="closeExportError">Aceptar</CButton>
        </div>
      </CCol>
    </CRow>
    <CRow v-if="showBools.table" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <table-purchases-adhoc
          :table-data="tableData"
          :request-params="requestParams"
        >
        <span slot="area"> {{ selectedArea }} {{ selectedArea && ' - ' }} </span>
        <span slot="period">{{ selectedPeriod }} / </span>
        <span slot="fiscalYear">{{ selectedFY }}</span>
      </table-purchases-adhoc>
      </CCol>
    </CRow>
    </div>
  </template>
  
  <script>
  import LogoExcel from "@/components/atoms/LogoExcel";
  import FiltersAreaCombo from "@/components/organisms/FiltersAreaCombo";
  import FilterFY from "@/components/molecules/FilterFY";
  import FilterMonthsRange from "@/components/molecules/FilterMonthsRange";
  import Api from "@/services/Api";
  import Loader from "@/components/atoms/Loader";
  import TablePurchasesAdhoc from "@/components/organisms/TablePurchasesAdhoc";
  import formatNum from "@/utils/formatNum";
  import {saveAs} from "file-saver";
  import formatFiscalYear from "@/helpers/formatFiscalYear";
  import getAreaName from "@/helpers/getAreaName";
  
  const requireTemplate = require.context('@/assets/documents', false, /\.xlsx$/);

  export default {
    name: "LoadManager",
    components: {
      FiltersAreaCombo,
      FilterFY,
      FilterMonthsRange,
      Loader,
      LogoExcel,
      TablePurchasesAdhoc
  },
    data() {
      return {
        filtersAreaList: [ "countries", "regionals", "aspms", "concessions"],
        activeItems: [],
        areaError: false,
        user: null,
        lang: null,
        messages: null,
        tableData: null,
        requestParams: null,
        showBools: {
          loader: false,
          table: false,
          importSubmitButton: false,
          fileInput: false,
          okMessage: false,
          koMessage: false,
          exportKoMessage: false,
        },
        selectedArea: null,
        validateAreaParams: false,
        selectedFile: [],
        exportParamsList: [],
        paramShowFinalized: 0,
        errorCode: null,
        errorMessage: '',
        exportErrorCode: null,
        cardCollapse: true,
        selectedFY: null,
        selectedPeriod: null
      };
    },
    computed: {
    },
    methods: { 
      mountFiltersAreaCombo(filtersNames) {
        this.activeItems = filtersNames;
      },
      hideTableOnImport(e) {
        e === 1 ? 
        this.showBools.table = false : 
        this.tableData !== null ?
        this.showBools.table = true : 
        null ;
      },  
      submitFilterValues(event) {
        event.preventDefault();
        if (this.checkFilterValues()) {
          this.requestParams = this.getParams();
          this.makeApiQuery(this.requestParams);
        }
      },
      checkFilterValues() {
        if (!this.$store.getters.getFiltersAreaComboParams) {
          this.areaError = true;
          return false;
        } else {
          this.areaError = false;
          this.invoicingNumberError = false;
          return true;
        }
      },
      getParams() {
        const currentLang = this.$store.getters.getCurrentLang == "es" ? 1 : 2;
        return {
          ...this.$store.getters.getFiltersAreaComboParams,
          fiscalYear: this.$store.getters.getFilterFYValue,
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
          languageId: currentLang,
        };
      },
      makeApiQuery(params) {
        this.setTableTitle(params);
        this.showBools.loader = true;
        this.showBools.table = false;
        Api.sendPost("admin/adhocPurchases", params).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.createTable(response.data.data)
            } else {
              this.showExportError(response.data.code);
            }
          }
        });
      },
      processRawData(data) {
        data.map(item => {
          if (!isNaN(item.purchases) && isFinite(item.purchases)) {
              item.purchases =`${formatNum.get(item.purchases, 2)}€`
          } else if (isNaN(item.purchases) || !isFinite(item.purchases)) {
            item.purchases = "-";
          } 
        });
        return data;
      },
      createTable(data) {
        this.showBools.loader = false;
        this.showBools.table = true;
        const processedData = this.processRawData(data);
        this.tableData = processedData;
      },
      requestDownload(event) {
        if (this.checkFilterValues()) {
          const button = event.target;
          event.preventDefault();
          button.setAttribute("disabled", "disabled");
          this.showBools.loader = true;
          this.requestParams = this.getParams();
          Api.sendPost(
            "/admin/adhocPurchases/downloadExcel",
            this.requestParams,
            { responseType: "blob" }
          ).then((response) => {
            if (response.status == 200) {
              this.dowloadFile(response);
            } else {
              this.showExportError(response.data.code);
            }
          });
          setTimeout(function () {
            button.removeAttribute("disabled");
          }, 2500);
        }
      },
      dowloadFile(response) {
        const contentDisposition = response.headers["content-disposition"];
        const fileName = contentDisposition.match(/filename=(.*?);/)[1];
        const contentType = contentDisposition.match(/content-type=(.*$)/)[1];
        const data = response.data;
        if (data && fileName && contentType) {
          const blob = new Blob([data], {type: contentType,});
          saveAs(blob, fileName);  
        }   
        this.showBools.loader = false;
      },
      updateImportFields(selectedValue) {
        this.importSelectedReport = selectedValue;
        this.removeSelectedFile(0);
        this.showBools.importSubmitButton = selectedValue !== null;
        this.showBools.fileInput = selectedValue !== null;
        this.showBools.okMessage = false;
        this.showBools.koMessage = false;
      },
      updateSelectedFile() {
        const file = this.$refs.file.files
        if (file[0]) {
          file[0].size > 10 * 1024 * 1024 ? this.showKoMessage('10MB') : this.selectedFile = [...file];
        }
      },
      removeSelectedFile(i) {
        this.selectedFile.splice(i, 1);
      },
      dragover(event) {
        event.preventDefault();
        if (!event.currentTarget.classList.contains('bg-green')) {
          event.currentTarget.classList.remove('bg-gray-100');
          event.currentTarget.classList.add('bg-green');
        }
      },
      dragleave(event) {
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green');
      },
      drop(event) {
        event.preventDefault();
        // Extract the files from the event
        const files = event.dataTransfer.files;
        if (files.length) {
          const file = files[0];
          event.currentTarget.classList.add('bg-gray-100');
          event.currentTarget.classList.remove('bg-green');
          // Check if the MIME type matches .xlsx
          if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              this.showKoMessage('xlsx')
          } else {
            this.$refs.file.files = files;
            this.updateSelectedFile();
          }
        }
      },
      importData(event) {
        event.preventDefault();
        const file = this.selectedFile[0];
        if(file) {
          const button = event.target.querySelector('button');
          button.setAttribute("disabled", "disabled");
          this.showBools.loader = true;
          const formData = new FormData();
          formData.append('file', file);
          Api.sendPost('/admin/adhocPurchases/upload', formData).then(
            (response) => {
              button.removeAttribute("disabled");
              this.showBools.loader = false;
              if (response.status === 200 ) {
                if (response.data.code === 200) {
                  this.showOkMessage();
                } else {
                  this.showKoMessage(response.data.code, response.data.message );
                }
              }
            }
          );
        }
      },
      showOkMessage() {
        this.showBools.okMessage = true;
        this.removeSelectedFile(0);
      },
      showKoMessage(error, message) {
        this.errorCode = error === 'xlsx' ? ''
        : error === '10MB' ? ''  
        : error;
        this.errorMessage = error === 906 ? message 
        : error === 'xlsx' ? this.messages.errors['xlsx']
        : error === '10MB' ? this.messages.errors['10MB']
        : 'No se han cargado los datos';
        this.removeSelectedFile(0);
        this.showBools.koMessage = true;
      },
      showExportError(errorCode) {
        this.exportErrorCode = errorCode;
        this.showBools.loader = false;
        this.showBools.exportKoMessage = true;
      },
      closeExportError() {
        this.showBools.exportKoMessage = false;
        this.exportErrorCode = null;
      },
      downloadTemplate(fileName) {
        const fileUrl = requireTemplate(`./${fileName}`);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.target = '_blank';
        link.click();
      },
      setTableTitle(params) {
        this.selectedArea = getAreaName(params, this.messages);
        this.selectedFY = formatFiscalYear(params.fiscalYear);
        const sinceMonth = this.messages.filters.months[params.sinceMonth];
        const upToMonth = this.messages.filters.months[params.upToMonth]
        this.selectedPeriod = sinceMonth === upToMonth ? sinceMonth : `de ${sinceMonth} a ${upToMonth}`;
      },
    },
    created() {
      this.user = this.$store.getters.getCurrentUser;
      this.lang = this.$store.getters.getCurrentLang;
      this.messages = this.$store.getters.getLangMessages;
      this.mountFiltersAreaCombo(this.filtersAreaList);
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
    .tabs {
      a {
        background-color: #ffffff;
      }
    } 
    .tab-card {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: none;
      border-color: #c4c9d0;
      margin-bottom: 0px;
    }
    .w-0 {
      width: 0px;
    }
    .h-0 {
      height: 0px;
      line-height: 0px;
    }
    .file-box {
      text-align: center;
      padding: 2rem;
      border-radius: 4px;
    }
    .bg-green {
      background: #7dc74e;
    }
    .ok-message {
      text-align: center;
      font-size: 1rem;
    }
    .bt-0 {
      border-top: none;
    }
    .b-radius-0 {
      border-radius: 0px;
    }
    .b-grey {
      border-color: #c4c9d0;
      border-bottom-color: #d8dbe0;
    }
  </style>
  
  
  