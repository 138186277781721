<template>
    <CCard>
      <CCardHeader class="flex flex--space-between flex--align-items-baseline">
        <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
          <strong>{{ messages.tables.results }} </strong>
          <slot name="area"></slot>
          <slot name="period"></slot>
          <slot name="fiscalYear"></slot>
        </span>
        <div>
          <CButton
            color="light"
            class="gtm-tag-export"
            @click="exportHTMLToExcel"
          >
            {{ messages.tables.export.toUpperCase() }}
            <LogoExcel />
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          hover
          outlined
          border
          :table-filter="{
            label: messages.tables.filter,
            placeholder: `${messages.tables.search} ...`,
          }"
          cleaner
          :noItemsView="{
            noItems: messages.tables.noItems,
            noResults: messages.tables.noResults,
          }"
          clickableRows
          id="excelArea"
          :fields="tableFields"
          :items="tableItems"
        >
          <template #colSpan="{ item }">
            <td :colspan="item.colSpan"></td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </template>
  
  <script>
  import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";
  import LogoExcel from "@/components/atoms/LogoExcel";
  export default {
    name: "TablePurchasesAdhoc",
    components: {
      LogoExcel,
    },
    props: {
      tableData: {
        type: Array,
        required: true,
      },
      requestParams: {
        type: Object,
        required: true,
      },
    },
    computed: {
      messages() {
        return this.$store.getters.getLangMessages;
      },
      tableFields() {
        const cells = [
          {
            key: "productCode",
            label: this.messages.purchasesAdhoc.product.toUpperCase(),
          },
          {
            key: "purchases",
            label: this.messages.purchasesAdhoc.purchases.toUpperCase(),
          },
          {
            key: "amount",
            label: this.messages.purchasesAdhoc.amount.toUpperCase(),
          }
        ];
  
        return cells;
      },
      tableItems() {
        this.tableData.map((item) => {
          for (let key in item) {
            if (item[key] === null) {
              item[key] = "-";
            }
          }
        });
        return this.tableData;
      },
    },
    methods: {
      exportHTMLToExcel() {
        ExportHTML2Excel(
          document.querySelector("#excelArea table.table"),
          this.messages.purchasesAdhoc.title
        );
      },
    },
  };
  </script>
  
  <style lang="scss">
  thead {
    .thead-top {
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      background: #666666;
      height: 32px;
      vertical-align: middle;
      border-left: 1px solid #dddddd;
    }
    .headCell {
      font-weight: bold;
      background: #dddddd;
      height: 32px;
      vertical-align: middle;
      text-align: center;
  
      &.dealer {
        text-align: left;
      }
    }
  }
  
  .totals-ew-sc {
    font-weight: 700;
    border-bottom: 1px solid #d8dbe0;
  
    td:first-child {
      border-right-color: transparent;
    }
  }
  $low: #da2840;
  $medium: #fac928;
  $high: #60d1b1;
  
  .result {
    &--low {
      color: $low;
    }
  }
  </style>
  