var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"flex flex--space-between flex--align-items-baseline"},[_c('span',{staticClass:"col-xs-12 col-md-9 mb-2 mb-md-0 p-0"},[_c('strong',[_vm._v(_vm._s(_vm.messages.tables.results)+" ")]),_vm._t("area"),_vm._t("period"),_vm._t("fiscalYear")],2),_c('div',[_c('CButton',{staticClass:"gtm-tag-export",attrs:{"color":"light"},on:{"click":_vm.exportHTMLToExcel}},[_vm._v(" "+_vm._s(_vm.messages.tables.export.toUpperCase())+" "),_c('LogoExcel')],1)],1)]),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","outlined":"","border":"","table-filter":{
        label: _vm.messages.tables.filter,
        placeholder: `${_vm.messages.tables.search} ...`,
      },"cleaner":"","noItemsView":{
        noItems: _vm.messages.tables.noItems,
        noResults: _vm.messages.tables.noResults,
      },"clickableRows":"","id":"excelArea","fields":_vm.tableFields,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"colSpan",fn:function({ item }){return [_c('td',{attrs:{"colspan":item.colSpan}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }